

























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwSwiperPagination } from '@atoms';
import { DcwPeopleSlider } from '@organisms';
import { useSwiper } from '@/composables';
import { HeadlineTypes, SectionWithPeopleContent } from '@/types';

export const DcwSectionWithPeople = defineComponent({
  name: 'DcwSectionWithPeople',
  components: { DcwNewHeadline, DcwSwiperPagination, DcwPeopleSlider },
  props: {
    content: {
      type: Object as PropType<SectionWithPeopleContent>,
      required: true
    },
    isCarousel: {
      type: Boolean,
      default: true
    },
    titleStyle: {
      type: String as PropType<HeadlineTypes>,
      default: HeadlineTypes.H3
    }
  },
  setup(props) {
    const slidesCount = Math.round(props.content.people.length / 3);

    const { onSlideChange, swiperInfo, nextSlideHandler, prevSlideHandler } = useSwiper({ length: props.content.people.length });

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: props.titleStyle
    };

    return { onSlideChange, swiperInfo, nextSlideHandler, prevSlideHandler, slidesCount, headlineProps };
  }
});

export default DcwSectionWithPeople;
