
























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwImage, DcwScrollSnap } from '@atoms';
import { SectionWithGalleryContent, GalleryItemType } from '@/types';
import { useLocales } from '@/composables';
import PlayIcon from '@/assets/icons/streamline-icons/play-icon.svg';

const MAX_GALLERY_SIZE = 4;

export const DcwGalleryGrid = defineComponent({
  name: 'DcwGalleryGrid',
  components: { PlayIcon, DcwImage, DcwScrollSnap },
  props: {
    content: {
      type: Array as PropType<SectionWithGalleryContent['gallery']>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const galleryLength = props.content.length > MAX_GALLERY_SIZE ? MAX_GALLERY_SIZE : props.content.length;

    return { GalleryItemType, translation, galleryLength };
  }
});

export default DcwGalleryGrid;
