






































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwButton, DcwNewHeadline, DcwImage } from '@atoms';
import { DcwContentBlock, DcwScheduleModal } from '@molecules';
import { ButtonVariants, HeadlineSubtitleSizes, HeadlineTypes, CtaSectionContent, HeadlineSubtitleSpacing } from '@/types';
import LinkedInIcon from '@/assets/brands/linkedin.svg';

export const DcwTextPageCTA = defineComponent({
  name: 'DcwTextPageCTA',
  components: { DcwButton, DcwContentBlock, DcwNewHeadline, DcwImage, DcwScheduleModal, LinkedInIcon },
  props: {
    content: {
      type: Object as PropType<CtaSectionContent>,
      required: true
    }
  },
  setup(props) {
    const isModalShown = ref(false);

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H3,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const extraHeadlineProps = {
      before: `${props.content.extraInfo.model.name} ${props.content.extraInfo.model.surname}`,
      subtitle: props.content.extraInfo.model.position,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.EXTRA_SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      isTinted: true
    };

    const firstButtonProps = {
      ...props.content.firstButton,
      variant: ButtonVariants.PRIMARY,
      icon: false
    };

    const secondButtonProps = {
      text: props.content.secondButton.text,
      variant: ButtonVariants.TERTIARY,
      isLink: !!props.content.secondButton.to,
      href: props.content.secondButton.to
    };

    return { isModalShown, headlineProps, firstButtonProps, secondButtonProps, extraHeadlineProps };
  }
});

export default DcwTextPageCTA;
