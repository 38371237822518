










































import { computed, defineComponent, PropType, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { DcwCompanyEventGallery } from './DcwCompanyEventGallery.vue';
import { formatDateString, isFutureDate, scrollToSection } from '@/helpers';
import { DcwNewHeadline, DcwButton } from '@atoms';
import { ButtonVariants, CompanyEvent, HeadlineSubtitleSizes, HeadlineTypes, PagesLinks, SliderDirection } from '@/types';
import { useGsap, useLocales } from '@/composables';

export const DcwCompanyEvent = defineComponent({
  name: 'DcwCompanyEvent',
  components: { DcwNewHeadline, DcwButton, DcwCompanyEventGallery },
  props: {
    content: {
      type: Object as PropType<CompanyEvent>,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    },
    slideIndex: {
      type: Number,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    },
    direction: {
      type: String as PropType<SliderDirection>,
      required: true
    },
    nextDate: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const route = useRoute();
    const { translation } = useLocales();
    const { timeLine: tl } = useGsap();
    const indicatorRef = ref<HTMLDivElement | null>(null);

    const isFuture = isFutureDate(props.content.date);

    const isLastSlide = computed(() => {
      if (isFuture) {
        return;
      }

      return isFutureDate(props.nextDate);
    });

    const highlightContent = `${props.content.headline.highlight ? props.content.headline.highlight : ''} ${
      isFuture ? '#upcoming' : ''
    }`;

    const headlineProps = {
      ...props.content.headline,
      highlight: highlightContent,
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      mobileStyledAs: HeadlineTypes.H5,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      mobileSubtitleSize: HeadlineSubtitleSizes.NO,
      isTinted: true
    };

    const buttonProps = {
      ...props.content.button,
      isLink: !!props.content?.button?.href,
      variant: ButtonVariants.TERTIARY,
      target: '_blank'
    };

    const mobileButtonProps = {
      ...props.content.button,
      text: translation.buttons.readMore,
      isLink: !!props.content?.button?.href,
      target: '_blank'
    };

    const dateString = formatDateString(props.content.date);

    const setLineWidth = (width: string, duration = 1, delay = 0) => {
      if (!indicatorRef.value) {
        return;
      }

      tl.to(indicatorRef.value, { width, duration, delay, ease: 'Power2.easeInOut' });
    };

    watch(
      () => props.isActive,
      (newValue) => {
        if (isFuture) {
          return;
        }

        if (newValue && isLastSlide.value && props.direction === SliderDirection.NEXT) {
          setLineWidth('50%', 0.5, 0.5);
          return;
        }

        if (!newValue && isLastSlide.value && props.direction === SliderDirection.NEXT) {
          return;
        }

        if (!newValue && isLastSlide.value && props.direction === SliderDirection.PREV) {
          setLineWidth('0', 0.5);
          return;
        }

        if (!newValue && props.direction === SliderDirection.NEXT) {
          setLineWidth('100%');
          return;
        }

        if (newValue && props.direction === SliderDirection.PREV && !isLastSlide.value) {
          setLineWidth('0');
        }
      }
    );

    watch(
      () => props.activeIndex,
      () => {
        if (props.slideIndex < props.activeIndex) {
          setLineWidth('100%');
        }
      }
    );

    const scrollIfSamePage = (event: MouseEvent) => {
      if (!props.content?.button?.href.match(/\/career#|\/career$/) || route.value.path.includes(PagesLinks.CONTACT)) {
        return;
      }
      event.preventDefault();

      if (!props.content?.button?.href.includes('#')) {
        scrollToSection();
        return;
      }

      scrollToSection(props.content?.button?.href.split('#')[1]);
    };

    return { isFuture, dateString, buttonProps, isLastSlide, indicatorRef, headlineProps, scrollIfSamePage, mobileButtonProps };
  }
});

export default DcwCompanyEvent;
