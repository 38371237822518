










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwImage } from '@atoms';
import { CompanyEvent } from '@/types';

export const DcwCompanyEventGallery = defineComponent({
  name: 'DcwCompanyEventGallery',
  components: { DcwImage },
  props: {
    gallery: {
      type: Object as PropType<CompanyEvent['gallery']>,
      required: true
    }
  }
});

export default DcwCompanyEventGallery;
