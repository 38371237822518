




























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwImage } from '@atoms';
import {
  CssVariables,
  HeadlineTypes,
  ProjectSelectors,
  HeadlineSubtitleSizes,
  TextHeroSectionContent,
  MainGridWrapperElements
} from '@/types';
import { useStyleSheet } from '@/composables/use-style-sheet';

export const DcwTextPageHero = defineComponent({
  name: 'DcwTextPageHero',
  components: { DcwGridWrapper, DcwNewHeadline, DcwImage },
  props: {
    content: {
      type: Object as PropType<TextHeroSectionContent>,
      required: true
    },
    isJobs: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { updateStyleSheet } = useStyleSheet();

    updateStyleSheet([
      {
        selectors: [ProjectSelectors.HEADER],
        properties: [{ key: CssVariables.COLOR_BACKGROUND, value: `var(${CssVariables.COLOR_SKY_BLUE})` }]
      }
    ]);

    const headlineProps = {
      ...props.content.headline,
      isTinted: true,
      type: HeadlineTypes.H1,
      mobileStyledAs: HeadlineTypes.H3,
      tabletStyledAs: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    return { MainGridWrapperElements, headlineProps };
  },
  head: {}
});

export default DcwTextPageHero;
