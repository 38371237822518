




















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwImage, DcwText } from '@atoms';
import { Testimonial } from '@/types';

export const DcwTestimonialSlide = defineComponent({
  name: 'DcwTestimonialSlide',
  components: { DcwImage, DcwText },
  props: {
    slide: {
      type: Object as PropType<Testimonial>,
      required: true
    }
  }
});

export default DcwTestimonialSlide;
