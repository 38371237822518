

































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwButton, DcwSocialMedia, DcwNewHeadline } from '@atoms';
import { DcwVideo, DcwContentBlock, DcwVideoModal } from '@molecules';
import { useLocales } from '@/composables';
import {
  HeadlineTypes,
  HeadlineColor,
  ButtonVariants,
  ContentBlockSpacings,
  HeadlineSubtitleSizes,
  BannerWithVideoContent,
  MainGridWrapperElements
} from '@/types';

export const DcwBannerWithVideo = defineComponent({
  name: 'DcwBannerWithVideo',
  components: { DcwVideo, DcwButton, DcwVideoModal, DcwNewHeadline, DcwGridWrapper, DcwSocialMedia, DcwContentBlock },
  props: {
    content: {
      type: Object as PropType<BannerWithVideoContent>,
      required: true
    },
    hasSocialMedia: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const videoRef = ref<Vue | null>(null);
    const showModal = ref(false);

    const videoButtonProps = {
      isPlay: true,
      text: translation.buttons.watchVideo,
      variant: ButtonVariants.TERTIARY
    };

    watch(showModal, () => {
      const video = videoRef.value?.$refs.videoRef as HTMLVideoElement;

      video && (showModal.value ? video.pause() : video.play());
    });

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H1,
      mobileStyledAs: HeadlineTypes.H3,
      color: HeadlineColor.LIGHT,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const contentBlockProps = {
      spacing: ContentBlockSpacings.EXTRA_LARGE
    };

    const videoProps = {
      isLoop: true,
      isMuted: true,
      isInline: true,
      isAutoplay: true,
      ...props.content.video
    };

    const socialMediaLinks = [
      translation.socialMedia.clutch,
      translation.socialMedia.facebook,
      translation.socialMedia.linkedIn,
      translation.socialMedia.vimeo,
      translation.socialMedia.instagram
    ];

    return {
      videoRef,
      showModal,
      videoProps,
      headlineProps,
      videoButtonProps,
      socialMediaLinks,
      contentBlockProps,
      MainGridWrapperElements
    };
  }
});

export default DcwBannerWithVideo;
