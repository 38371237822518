
















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGalleryGrid } from './components/DcwGalleryGrid.vue';
import { DcwNewHeadline, DcwGridWrapper } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineTypes, SectionWithGalleryContent, GalleryItemType } from '@/types';
import { useLocales } from '@/composables';

export const DcwSectionWithGallery = defineComponent({
  name: 'DcwSectionWithGallery',
  components: { DcwNewHeadline, DcwGridWrapper, DcwGalleryGrid },
  props: {
    content: {
      type: Object as PropType<SectionWithGalleryContent>,
      required: true
    },
    isReversed: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const itemHeadlineProps = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { headlineProps, itemHeadlineProps, GalleryItemType, translation };
  }
});

export default DcwSectionWithGallery;
