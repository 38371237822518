










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import * as SwiperClass from 'swiper';
import { DcwTestimonialSlide } from './DcwTestimonialSlide.vue';
import { Testimonial, SwiperPropsOptions, Breakpoints } from '@/types';
import { UseSwiper } from '@/composables';
import { DcwSwiper } from '@atoms';

export const DcwTestimonialSlider = defineComponent({
  name: 'DcwTestimonialSlider',
  components: { DcwTestimonialSlide, DcwSwiper },
  props: {
    slides: {
      type: Array as PropType<Testimonial[]>,
      required: true
    },
    swiperInfo: {
      type: Object as PropType<UseSwiper['swiperInfo']>,
      required: true
    }
  },
  emits: ['slide-change'],
  setup(props, context) {
    const swiperOptions: SwiperPropsOptions<Testimonial> = {
      swiperConfig: {
        slidesPerView: 'auto',
        centeredSlides: true,
        speed: 300,
        loop: true,
        spaceBetween: 24,
        allowTouchMove: true,
        breakpoints: {
          [Breakpoints.MEDIUM]: {
            allowTouchMove: false
          }
        }
      },
      slides: props.slides,
      onSlideChange: (instance: SwiperClass.Swiper) => {
        context.emit('slide-change', instance);
      }
    };

    return { swiperOptions };
  }
});

export default DcwTestimonialSlider;
