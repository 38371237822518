





























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DateTime } from 'luxon';
import { DcwCompanyEvent } from './components/DcwCompanyEvent.vue';
import { DcwSwiperPagination, DcwSwiper, DcwNewHeadline, DcwGridWrapper } from '@atoms';
import {
  SwiperRef,
  CompanyEvent,
  HeadlineTypes,
  SwiperPropsOptions,
  HeadlineSubtitleSizes,
  CompanyEventsSectionContent
} from '@/types';
import { useSwiper } from '@/composables';
import { getInitialSlideByDate } from '@/helpers';

export const DcwCompanyEvents = defineComponent({
  name: 'DcwCompanyEvents',
  components: { DcwSwiperPagination, DcwSwiper, DcwNewHeadline, DcwGridWrapper, DcwCompanyEvent },
  props: {
    content: {
      type: Object as PropType<CompanyEventsSectionContent>,
      required: true
    }
  },
  setup(props) {
    const swiperRef = ref<SwiperRef | null>(null);
    const sortedSlides = [...props.content.events].sort((a, b) => {
      const aMilis = DateTime.fromISO(a.date);
      const bMilis = DateTime.fromISO(b.date);

      return aMilis < bMilis ? -1 : aMilis > bMilis ? 1 : 0;
    });

    const initialSlide = getInitialSlideByDate(sortedSlides);
    const { onSlideChange, nextSlideHandler, prevSlideHandler, swiperInfo } = useSwiper({
      length: props.content.events.length,
      isCarousel: false,
      sliderRef: swiperRef,
      initialSlide
    });

    const swiperOptions: SwiperPropsOptions<CompanyEvent> = {
      slides: sortedSlides,
      onSlideChange,
      swiperConfig: {
        initialSlide,
        speed: 1000,
        slidesPerView: 1.1,
        breakpoints: {
          360: {
            slidesPerView: 1.24
          },
          480: {
            slidesPerView: 1.7
          },
          650: {
            slidesPerView: 2
          },
          1025: {
            width: 732,
            slidesPerView: 1,
            allowTouchMove: false
          }
        }
      }
    };

    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { swiperRef, swiperInfo, swiperOptions, headlineProps, nextSlideHandler, prevSlideHandler };
  }
});

export default DcwCompanyEvents;
