

























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwJoinUsCardFooter } from './DcwJoinUsCardFooter.vue';
import { DcwImage, DcwButton, DcwNewHeadline } from '@atoms';
import { DcwContentBlock } from '@molecules';
import { ContentBlockSpacings, CtaCardProps, HeadlineSubtitleSizes } from '@/types';
import { desktopNavigateTo } from '@/helpers';

export const DcwJoinUsCard = defineComponent({
  name: 'DcwJoinUsCard',
  components: { DcwImage, DcwButton, DcwNewHeadline, DcwContentBlock, DcwJoinUsCardFooter },
  props: {
    data: {
      type: Object as PropType<CtaCardProps>,
      required: true
    },
    isRight: {
      type: Boolean,
      default: false
    },
    isLeft: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.data.headline,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const contentBlockProps = {
      spacing: ContentBlockSpacings.EXTRA_LARGE
    };

    const buttonProps = {
      text: props.data.button.text,
      href: props.data.button.href,
      isLink: !!props.data.button.href
    };

    return { contentBlockProps, buttonProps, headlineProps, desktopNavigateTo };
  }
});

export default DcwJoinUsCard;
