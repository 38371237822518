



































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwImage, DcwButton } from '@atoms';
import { DcwScheduleModal } from '@molecules';
import LinkedInIcon from '@/assets/brands/linkedin.svg';
import { ButtonVariants, CtaCardProps } from '@/types';

export const DcwJoinUsCardFooter = defineComponent({
  name: 'DcwJoinUsCardFooter',
  components: {
    DcwImage,
    DcwButton,
    DcwScheduleModal,
    LinkedInIcon
  },
  props: {
    data: {
      type: Object as PropType<CtaCardProps['person']>,
      required: true
    }
  },
  setup() {
    const isModalShown = ref(false);

    return {
      ButtonVariants,
      isModalShown
    };
  }
});

export default DcwJoinUsCardFooter;
