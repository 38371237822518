




















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwTestimonialSlider } from './components/DcwTestimonialSlider.vue';
import { HeadlineSubtitleSizes, HeadlineTypes, MainGridWrapperElements, TestimonialSectionContent } from '@/types';
import { DcwGridWrapper, DcwNewHeadline, DcwSwiperPagination } from '@atoms';
import { useSwiper } from '@/composables';

export const DcwTestimonial = defineComponent({
  name: 'DcwTestimonial',
  components: { DcwGridWrapper, DcwNewHeadline, DcwSwiperPagination, DcwTestimonialSlider },
  props: {
    isCentered: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object as PropType<TestimonialSectionContent>,
      required: true
    }
  },
  setup(props) {
    const { nextSlideHandler, prevSlideHandler, swiperInfo, onSlideChange } = useSwiper({ length: props.content.slides.length });

    const headline = {
      ...props.content.headline,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const slides = props.content.slides;

    return { slides, headline, swiperInfo, onSlideChange, prevSlideHandler, nextSlideHandler, MainGridWrapperElements };
  }
});

export default DcwTestimonial;
