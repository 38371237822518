































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwBadge, DcwButton, DcwSwiper, DcwImage } from '@atoms';
import { DcwProjectItem } from '@organisms';
import { useLocales, useSwiper } from '@/composables';
import { DcwSwiperButtons, DcwProjectData } from '@molecules';
import { getHEXOpacityColor } from '@/helpers';
import {
  Project,
  PagesLinks,
  ProjectIDs,
  Breakpoints,
  HeadlineTypes,
  ButtonVariants,
  SwiperPropsOptions,
  HeadlineSubtitleSizes,
  ProjectsSectionContent,
  MainGridWrapperElements
} from '@/types';
import { offersService } from '@/service/model';

export const DcwProjectsSection = defineComponent({
  name: 'DcwProjectsSection',
  components: {
    DcwBadge,
    DcwImage,
    DcwButton,
    DcwSwiper,
    DcwGridWrapper,
    DcwProjectItem,
    DcwProjectData,
    DcwNewHeadline,
    DcwSwiperButtons
  },
  props: {
    content: {
      type: Object as PropType<ProjectsSectionContent & { projects: Project[] }>,
      required: true
    },
    hasLabel: {
      type: Boolean,
      default: false
    },
    hasAllButton: {
      type: Boolean,
      default: false
    },
    hasContactButton: {
      type: Boolean,
      default: false
    },
    isHomeVariant: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const { getOffersByProjectId } = offersService;

    const headlineProps = {
      subtitle: props.content.headline.subtitle,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    const buttonProps = {
      text: translation.buttons.seeAllProjects,
      variant: ButtonVariants.SECONDARY,
      isLink: true,
      href: PagesLinks.PROJECTS
    };

    const contactButtonProps = {
      text: translation.buttons.contact,
      variant: ButtonVariants.TERTIARY,
      isLink: true,
      href: PagesLinks.CONTACT
    };

    const { onSlideChange, nextSlideHandler, prevSlideHandler, swiperInfo } = useSwiper();

    const swiperOptions: SwiperPropsOptions<Project> = {
      swiperConfig: {
        slidesPerView: 1,
        spaceBetween: 200,
        speed: 1000,
        loop: true,
        breakpoints: {
          [Breakpoints.SMALL]: {
            spaceBetween: 1000
          },
          [Breakpoints.MEDIUM]: {
            spaceBetween: 1000,
            allowTouchMove: false
          }
        }
      },
      onSlideChange,
      slides: props.content.projects ? props.content.projects : []
    };

    const currentColor = computed(() => props.content.projects[swiperInfo.activeIndex].color);
    const borderColor = computed(() => getHEXOpacityColor(props.content.projects[swiperInfo.activeIndex].color).thirty);

    const swiperProps = computed(() => ({
      options: swiperOptions,
      activeSlide: swiperInfo.activeSlide
    }));

    const getProjectOffersCount = (id: ProjectIDs): string => {
      const projectOffers = getOffersByProjectId(id);
      return `${projectOffers.length} ${
        projectOffers.length === 1 ? translation.phrases.oneActiveJobOffer : translation.phrases.manyActiveJobOffers
      }`;
    };

    return {
      borderColor,
      swiperProps,
      buttonProps,
      currentColor,
      HeadlineTypes,
      headlineProps,
      nextSlideHandler,
      prevSlideHandler,
      contactButtonProps,
      getProjectOffersCount,
      MainGridWrapperElements
    };
  }
});

export default DcwProjectsSection;
