



























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwGridWrapper, DcwNewHeadline, DcwScrollSnap } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineTypes, HtmlWrappers, MainGridWrapperElements, SectionWithItemsContent } from '@/types';

export const DcwSectionWithItems = defineComponent({
  name: 'DcwSectionWithItems',
  components: { DcwGridWrapper, DcwNewHeadline, DcwScrollSnap },
  props: {
    content: {
      type: Object as PropType<SectionWithItemsContent>,
      required: true
    },
    hasScrollOnMobile: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      isTinted: true,
      type: HeadlineTypes.H2,
      subtitleSize: HeadlineSubtitleSizes.SMALL
    };

    const itemHeadlineProps = {
      type: HeadlineTypes.H3,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { MainGridWrapperElements, headlineProps, itemHeadlineProps, HtmlWrappers };
  }
});

export default DcwSectionWithItems;
