








import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwJoinUsCard } from './components/DcwJoinUsCard.vue';
import { DcwGridWrapper } from '@atoms';
import { JoinUsSectionContent, PagesLinks, ScheduleModalPeople } from '@/types';
import { desktopNavigateTo } from '@/helpers';

export const DcwJoinUsSection = defineComponent({
  name: 'DcwJoinUsSection',
  components: {
    DcwGridWrapper,
    DcwJoinUsCard
  },
  props: {
    content: {
      type: Object as PropType<JoinUsSectionContent>,
      required: true
    }
  },
  setup() {
    return { desktopNavigateTo, PagesLinks, ScheduleModalPeople };
  }
});

export default DcwJoinUsSection;
